<template>
  <div>
    <div class="dashboard-page">
      <div class="mb-2">
        <h1>
          {{ $t("Welcome back") }}, {{ user.first_name }}
          {{ user.last_name }}
        </h1>
        <p>
          {{
            $t(
              "Enhance your dental practice's efficiency with our all-in-one management solution"
            )
          }}.
        </p>
      </div>
      <div class="card">
        <div class="card-header">
          <h3>{{ $t("Cases Statistics") }}</h3>
        </div>
        <div class="card-body">
          <b-row>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Today") }}</h4>
                <h3>{{ cases.todayCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Week") }}</h4>
                <h3>{{ cases.thisWeekCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Month") }}</h4>
                <h3>{{ cases.thisMonthCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Total") }}</h4>
                <h3>{{ cases.totalCases }}</h3>
              </div>
            </b-col>
          </b-row>
          <div class="chart-header mt-2 mb-1">
            <div class="chart-header-left">
              <h3>{{ $t("Statistics") }}</h3>
            </div>
            <div class="chart-header-right d-flex align-items-center gap-2">
              <div class="calender d-flex align-items-center gap-1">
                <p>{{ $t("Last Week") }}</p>
                <DatePicker class="m-0" v-model="casesDate" />
              </div>
              <div class="customer-select d-flex align-items-center gap-1" style="width: 320px">
                <p>{{ $t("Customer") }}</p>
                <div class="w-100">
                  <MultiSelectInput
                    v-model="caseCustomerId"
                    :options="customers"
                    label="companyName"
                    trackBy="id"
                    :key="caseCustomerId"
                    moduleName="customers"
                    :multiple="false"
                    ><template #option="{ props }">
                      <div>
                        <p>
                          {{ props.option.companyNumber }} - {{ props.option.companyName }}
                        </p>
                      </div>
                    </template>
                  </MultiSelectInput>
                </div>
              </div>
            </div>
          </div>
          <div class="spiline-chart">
            <Chart :series="cases.series" />
          </div>
          <b-row>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Active") }}</h4>
                <h3>{{ cases.activeCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Flagged") }}</h4>
                <h3>{{ cases.flaggedCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Finished") }}</h4>
                <h3>{{ cases.finishedCases }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Trash") }}</h4>
                <h3>{{ cases.trashCases }}</h3>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header">
          <h3>{{ $t("Customers Statistics") }}</h3>
        </div>
        <div class="card-body">
          <b-row>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Today") }}</h4>
                <h3>{{ customersData.todayCustomers }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Week") }}</h4>
                <h3>{{ customersData.thisWeekCustomers }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Month") }}</h4>
                <h3>{{ customersData.thisMonthCustomers }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Total") }}</h4>
                <h3>{{ customersData.totalCustomers }}</h3>
              </div>
            </b-col>
          </b-row>
          <div class="chart-header mt-2 mb-1">
            <h3>{{ $t("Statistics") }}</h3>
            <div class="d-flex align-items-center gap-2">
              <p>{{ $t("Last Week") }}</p>
              <div class="calender">
                <DatePicker class="m-0" v-model="customersDate" />
              </div>
            </div>
          </div>
          <div class="spiline-chart">
            <Chart :series="customersData.series" />
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header">
          <h3>{{ $t("Invoices Statistics") }}</h3>
        </div>
        <div class="card-body">
          <b-row>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Today") }}</h4>
                <h3>{{ invoices.todayInvoices }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Week") }}</h4>
                <h3>{{ invoices.thisWeekInvoices }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("This Month") }}</h4>
                <h3>{{ invoices.thisMonthInvoices }}</h3>
              </div>
            </b-col>
            <b-col cols="6" lg="3" md="3">
              <div class="status-card mb-2 mb-lg-0">
                <h4>{{ $t("Total") }}</h4>
                <h3>{{ invoices.totalInvoices }}</h3>
              </div>
            </b-col>
          </b-row>
          <div class="chart-header mt-2 mb-1">
            <div class="chart-header-left">
              <h3>{{ $t("Statistics") }}</h3>

            </div>
            <div class="chart-header-right d-flex align-items-center gap-2">
              <div class="calender d-flex align-items-center gap-1">
                <p>{{ $t("Last Week") }}</p>
                <DatePicker class="m-0" v-model="invoicesDate" />
              </div>
              <div class="customer-select d-flex align-items-center gap-1" style="width: 320px">
                <p>{{ $t("Customer") }}</p>
                <div class="w-100">
                  <MultiSelectInput
                    v-model="companyId"
                    :options="customers"
                    label="companyName"
                    trackBy="id"
                    :key="companyId"
                    moduleName="customers"
                    :multiple="false"
                    ><template #option="{ props }">
                      <div>
                        <p>
                          {{ props.option.companyNumber }} - {{ props.option.companyName }}
                        </p>
                      </div>
                    </template>
                  </MultiSelectInput>
                </div>
              </div>
            </div>
          </div>
          <div class="spiline-chart">
            <Chart :series="invoices.series" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ElementIcon from "@/components/elements/Icon.vue";
import Chart from "./Chart.vue";
import { mapGetters } from "vuex";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import DatePicker from "@/components/DatePicker.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    ElementIcon,
    Chart,
    MultiSelectInput,
    DatePicker,
  },
  data() {
    return {
      cases: {
        activeCases: 0,
        finishedCases: 0,
        flaggedCases: 0,
        series: [],
        thisMonthCases: 0,
        thisWeekCases: 0,
        todayCases: 0,
        totalCases: 0,
        trashCases: 0,
      },
      customersData: {
        series: [],
        thisMonthCustomers: 0,
        thisWeekCustomers: 0,
        todayCustomers: 0,
        totalCustomers: 0,
      },
      invoices: {
        series: [],
        thisMonthInvoices: 0,
        thisWeekInvoices: 0,
        todayInvoices: 0,
        totalInvoices: 0,
      },
      casesDate: "",
      invoicesDate: "",
      customersDate: "",
      companyId: "",
      caseCustomerId: "",
    };
  },
  computed: {
    ...mapGetters("customers", ["customers"]),
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    casesDate: function (newVal, oldVal) {
      this.getCases();
    },
    customersDate: function (newVal, oldVal) {
      this.getCustomers();
    },
    invoicesDate: function (newVal, oldVal) {
      this.getInvoices();
    },
    companyId: function (newVal, oldVal) {
      this.getInvoices();
    },
    caseCustomerId: function (newVal, oldVal) {
      this.getCases();
    },
  },
  async created() {
    await this.$store.dispatch("customers/list");
  },
  async mounted() {
    await this.getStatistics();
  },
  methods: {
    async getStatistics() {
      try {
        this.$store.commit("showLoader", true);
        const response = await this.$store.dispatch("caseLists/dashboard");
        this.cases = response?.data?.cases ?? {};
        this.customersData = response?.data?.customers ?? {};
        this.invoices = response?.data?.invoices ?? {};
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async getCases() {
      const response = await this.$store.dispatch("caseLists/dashboard", {
        casesDate: this.casesDate,
        caseCustomerId: this.caseCustomerId?.id ?? null,
      });
      this.cases = response?.data?.cases ?? {};
    },
    async getCustomers() {
      const response = await this.$store.dispatch("caseLists/dashboard", {
        customersDate: this.customersDate,
      });
      this.customersData = response?.data?.customers ?? {};
    },
    async getInvoices() {
      console.log("Company ID:", this.companyId?.id);
      console.log("Invoices Date:", this.invoicesDate);
      const response = await this.$store.dispatch("caseLists/dashboard", {
        invoicesDate: this.invoicesDate ?? null,
        customerId: this.companyId?.id ?? null,
      });
      this.invoices = response?.data?.invoices ?? {};
    },
  },
};
</script>

<style></style>
